import { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { Layout, browser, CookieContext, Children } from '@adac/core-view';

// import MainPageContainer from './MainPageContainer';
import StoreContext from '../../stores';
import MainPageContainer from './MainPageContainer';
import FooterContainer from './FooterContainer';
import Header from './Header';
import OverlayContainer from './OverlayContainer';
import OverlayErrorContainer from '../overlays/OverlayErrorContainer';
import DevTools from '../dev/DevTools';

// add fake cookie context with COOKIES_ACCEPTED set to 'true', to disable our cookie banner, since it's added by ADAC
// const CookieBannerCookieProvider = ({ children }: {children: Children }) => <CookieProvider name="COOKIES_ACCEPTED">{children}</CookieProvider>;
const CookieBannerCookieProvider = ({ children }: { children: Children }) => {
  const contextValue = useMemo(
    () => ({
      value: 'true',
      setValue: () => {},
    }),
    []
  );

  return <CookieContext.Provider value={contextValue}>{children}</CookieContext.Provider>;
};

export const LayoutContainer = observer(() => {
  const stores = useContext(StoreContext);

  return (
    <ThemeProvider theme={stores.ui.currentTheme}>
      <CookieBannerCookieProvider>
        <Layout nav={() => <Header />} browser={browser}>
          <MainPageContainer afterElement={<FooterContainer />} />
          <OverlayContainer />
          <OverlayErrorContainer />
          <DevTools />
        </Layout>
      </CookieBannerCookieProvider>
    </ThemeProvider>
  );
});

export default LayoutContainer;
