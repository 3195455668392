import { RouterContextContainer } from '@adac/core-view';
import { useContext } from 'react';
import { Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { LayoutContainer } from './components/layout';
import DevelopmentHelperPages from './components/pages/DeveloperHelperPages';
import { useInjectHTMLScripts } from './hooks/useInjectHTMLScripts';
import { useRedirectWithAccessToken, useRedirectWithCaseToken } from './hooks/useRedirect';
import StoresContext from './stores';

const App = () => {
  useInjectHTMLScripts();
  const stores = useContext(StoresContext);
  const redirectWithAccessToken = useRedirectWithAccessToken();
  const redirectWithCaseToken = useRedirectWithCaseToken();

  return (
    <ThemeProvider theme={stores.ui.currentTheme}>
      <RouterContextContainer
        redirectWithCaseToken={redirectWithCaseToken}
        redirectWithAccessToken={redirectWithAccessToken}
        routes={[<Route pageTransition='slideUp' path='/dev' component={DevelopmentHelperPages} />]}
      >
        <LayoutContainer />
      </RouterContextContainer>
    </ThemeProvider>
  );
};

export default App;
