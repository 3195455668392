import { useContext } from 'react';
import { observer } from 'mobx-react';

import { WorkaroundStatusChanger } from '@adac/core-view';
import StoresContext from '../../stores';

export default observer(() => {
  const stores = useContext(StoresContext);

  return <WorkaroundStatusChanger status={stores.case.status} setStatus={(status: string) => stores.case.setWorkaroundStatusEventString(status)} />;
});
