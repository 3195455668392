import { createContext } from 'react';

import { themeConfig, CaseStore, UIStore, searchCaseByTokenForCustomer } from '@adac/core-view';

export interface Stores {
  ui: UIStore;
  case: CaseStore;
}

const uiStore = new UIStore(themeConfig);
const caseStore = new CaseStore(searchCaseByTokenForCustomer);
const stores: Stores = {
  ui: uiStore,
  case: caseStore,
};

// Use the React context API so that the stores are available anywhere in the app
export default createContext(stores);
