import { observer } from 'mobx-react';
import { useContext } from 'react';
import styled from 'styled-components';

import { __, CaseStatusList, formatDate, getClaimReference } from '@adac/core-model';
import { Button, CaseDocuments, CircleLoader, FlexView, LightSubTitle, SubPage, Text, Title, useLocation, WarningMessage } from '@adac/core-view';

import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import { useStatusListeners } from '../../hooks/useStatusListeners';
import StoreContext from '../../stores';

const FormLayout = styled.div`
  & > * {
    margin-bottom: 18px;
  }
`;

export default observer(() => {
  const { case: caseStore } = useContext(StoreContext);
  const { search } = useLocation();
  // TODO: more secure soliution to prevent others (snd admin or driver to submit survey results
  const showForm = !search && !search.match(/hideSurvey/);

  useStatusListeners(caseStore);

  const title = `${__('Hermes Id')}. ${getClaimReference(caseStore.commissioningReference)} · ${__('Datum')} ${formatDate(new Date())}`;

  const isInsurance = useIsInsuranceProduct();

  return (
    <>
      <SubPage>
        <Title>{__('Your documents')}</Title>
        {!isInsurance && <LightSubTitle>{title}</LightSubTitle>}

        {caseStore.status === CaseStatusList.IMAGES_UPDATED ? (
          <FlexView left center>
            <CircleLoader />
            <Text> {__('Your documents are currently being edited')}</Text>
          </FlexView>
        ) : (
          <CaseDocuments
            token={caseStore.token}
            commissionReport={caseStore.commissionReport}
            commissionExtraDamage={caseStore.commissionExtraDamage}
            finalStatusReport={caseStore.finalStatusReport?.customer}
          />
        )}

        {!isInsurance && (
          <WarningMessage marginTop='24px'>
            <Text>
              {__('info.invoicing.text.customer')} <a href={`mailto:${__('info.invoicing.email')}?subject=${title}`}>{__('info.invoicing.email')}</a>
            </Text>
          </WarningMessage>
        )}
      </SubPage>
      {showForm && (
        <SubPage style={{ margin: '40px 0px' }}>
          <FormLayout>
            {!caseStore.feedback && (
              <Button
                cta
                title={__('Feedback survey')}
                link='/survey'
                // disabled={caseStore.sendingSurvey}
              />
            )}
          </FormLayout>
        </SubPage>
      )}
    </>
  );
});
