import { useContext } from 'react';
import { CipheredToken } from '@adac/core-model';
import StoresContext from '../stores';

export function useRedirectWithAccessToken() {
  /**
   * Avoid destructuring fields from `stores.case`, as it may cause the store initialization to fail.
   */
  const stores = useContext(StoresContext);

  async function setCaseAndAccessToken(caseToken: CipheredToken, accessToken: string) {
    stores.case.setAccessToken(accessToken);
    const { token } = await stores.case.fetchByToken(caseToken);
    if (token) {
      window.location.href = `/${token}`;
    }
  }

  return setCaseAndAccessToken;
}
export function useRedirectWithCaseToken() {
  /**
   * Avoid destructuring fields from `stores.case`, as it may cause the store initialization to fail.
   */
  const stores = useContext(StoresContext);

  async function redirectWithCaseToken(urlToken: string) {
    const { token } = await stores.case.fetchByToken(urlToken);
    if (urlToken !== token && token) {
      window.location.href = `/${token}${window.location.search}`;
    }
  }

  return redirectWithCaseToken;
}
